import React from "react"
import { MobileJSMediaQuery } from "../styles/constants"

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false)
  React.useEffect(() => {
    matchMedia()

    window.addEventListener("resize", matchMedia)

    return () => {
      window.removeEventListener("resize", matchMedia)
    }
  }, [])

  const matchMedia = () => {
    setIsMobile(window.matchMedia(MobileJSMediaQuery).matches)
  }

  return isMobile
}
